.map {
  width: 100vw;
  height: calc(100vh - 40px);
}

.camera_coords_map {
  width: 1452px;
  height: 700px;
}

.cameras_map {
  width: 1040px;
  height: 790px;
}

.region .ant-select-selection-item, .region .ant-select-disabled .ant-select-selection-item, .farm .ant-select-selection-item, .farm .ant-select-disabled .ant-select-selection-item {
  color: #fff;
  font-weight: 500;
}

/* .ant-select-clear {
  top: 14px !important;
}
.feeding_page .ant-select-clear {
  top: 12px !important;
} */

.region .ant-select-selector {
  font-size: 1.4rem;
  font-weight: 700;
}

.ant-btn.ant-btn-primary:not(:disabled) {
  background-color: #1677ff;
}

.player-controls .ant-btn-text:not(:disabled):not(.ant-btn-disabled):hover {
  color: #fb923c;
  background-color: rgba(0, 0, 0, 0);
}

.player-controls .button_active.ant-btn-text:not(:disabled):not(.ant-btn-disabled):hover {
  color: #ef4444;
  background-color: rgba(0, 0, 0, 0);
}

.ant-upload {
  background-color: #fff;
  border-radius: 10px;
}

.ant-picker-focused {
  box-shadow: none;
}

.balloon {
  min-width: 300px;
  min-height: 20px;
}

.icon {
  font-family: HostingFont;
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  /* color: black; */
}

.icon_watch::after {
  content: '\e972';
}

.icon_watch_active::after {
  content: '\e971';
}

.icon_stop::after {
  content: '\e956';
}

.icon_stop_active::after {
  content: '\e958';
}

.icon_moving::after {
  content: '\e957';
}

.icon_connection::after {
  content: '\e95f';
}

.icon_trackline::after {
  content: '\e964';
}

.icon_area::after {
  content: '\e980';
}

.cameras-title .ant-picker {
  border-color: #e5e7eb;
}

.cameras-title .ant-picker:hover {
  border-color: #fff;
}

.cameras-title .ant-picker .ant-picker-input input, .cameras-title .ant-picker .ant-picker-input input::placeholder,
.cameras-title .ant-picker .ant-picker-suffix {
  color: #e5e7eb;
}

.camera_window .ant-radio-button-wrapper {
  background: none !important;
  border: none;
  border-inline-start: none !important;
  color: gray;
  font-size: 1rem;
}

.camera_window .ant-radio-button-wrapper:hover {
  color: black !important;
}

.camera_window .ant-radio-button-wrapper::before {
  width: 0 !important;
}

.camera_window .ant-radio-button-wrapper-checked {
  border: none !important;
  border-inline-start: none !important;
  color: black !important;
}

.ant-table-body {
  scroll-behavior: smooth;
}
.ant-tree-switcher {
  width: auto !important;
  padding: 5px;
  font-size: 1.5rem !important;
  display: flex;
  align-items: center;
}

.tabs .ant-tabs-content-holder {
  max-height: calc(100% - 45px);
  overflow-y: auto;
}

.areaList .ant-table-body{
  min-height: 560px;
}

/*.collapse_is_breakdown .ant-collapse-item:last-child>.ant-collapse-header  {*/
/*  display: none;*/
/*}*/

/*.collapse_is_breakdown .ant-collapse-content>.ant-collapse-content-box {*/
/*  padding: 0;*/
/*}*/

/*.collapse_is_breakdown .ant-collapse-ghost>.ant-collapse-item>.ant-collapse-content>.ant-collapse-content-box {*/
/*  padding-block: 0;*/
/*}*/

.breakdown .ant-checkbox-checked .ant-checkbox-inner,
.breakdown.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
.ant-checkbox-checked:not(.ant-checkbox-disabled)
.ant-checkbox-inner {
  background-color: #ff4d4f;
  border-color: #ff4d4f;
}

.breakdown.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
.ant-checkbox-checked:not(.ant-checkbox-disabled):after {
  border-color: #ff4d4f;
}

.breakdown .ant-checkbox-checked:after {
  border-color: #ff4d4f;
}

/* .ant-tree-switcher-line-icon {
  font-size: 2rem !important;
} */


/* .multicam_events_tree .ant-tree-list-holder-inner div { */
  /*width: 100%;*/
  /*margin: 0 auto;*/
  /*border-bottom: 1px solid white;*/
/* } */
/* .multicam_events_tree .ant-tree-node-content-wrapper { */
  /*width: 100%;*/
  /*border-bottom: 1px solid white;*/
/* } */

.multicam_events_tree .ant-tree-node-selected.ant-tree-node-selected {
  background-color: rgba(85,85,85, .5) !important;
}

.multicam_events_tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
  background-color: rgba(85,85,85, .5) !important;
}

/*.multicam_events_tree .ant-tree-node-selected {*/
/*  background-color: rgba(85,85,85, .5) !important;*/
/*}*/

/*.multicam_events_tree .ant-tree-treenode.ant-tree-treenode-selected::before {*/
/*  background-color: rgba(85,85,85, .5) !important;*/
/*}*/

/*.multicam_events_tree .ant-tree-treenode.ant-tree-treenode-selected::before:hover {*/
/*  background-color: rgba(85,85,85, .5) !important;*/
/*}*/

/*.multicam_events_tree .ant-tree.ant-tree-directory .ant-tree-treenode-selected::before {*/
/*  background-color: rgba(85,85,85, .5) !important;*/
/*}*/

/*.multicam_events_tree .ant-tree.ant-tree-directory .ant-tree-treenode-selected::before {*/
/*  background-color: rgba(85,85,85, .5) !important;*/
/*}*/

/* .ant-tree-list-scrollbar .ant-tree-list-scrollbar-show {
  width: 8px;
} */

.ant-tree-list-scrollbar-thumb {
  background: #555 !important;
  /* border-radius: 5px; */
}


/*.important_event_modal .ant-modal-content {*/
/*  background-color: rgb(55 65 81);*/
/*}*/

/*.important_event_modal .ant-modal-header {*/
/*  background-color: rgb(55 65 81);*/
/*}*/

/*.ant-btn-primary.button_auth:hover {*/
/*  background-color: #ec7e16;*/
/*}*/
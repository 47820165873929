@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "HostingFont";
  src: local("HostingFont"),
    url(./hostingFont-3cfa8edaa9491a8be0d71749813a58cc.ttf) format("truetype");
}
@font-face {
  font-family: "Roboto";
  src: local("Roboto"),
    url(/public/fonts/Roboto-Bold-f80816a5455d171f948d98c32f20c46e.ttf) format("truetype");
}
@font-face {
  font-family: "Roboto";
  src: local("Roboto"),
    url(/public/fonts/Roboto-Medium-7c8d04cd831df3033c8a96a2668d645e.ttf) format("truetype");
}
@font-face {
  font-family: "Roboto";
  src: local("Roboto"),
    url(/public/fonts/Roboto-Regular-fc2b5060f7accec5cf74437196c1b027.ttf) format("truetype");
}

/* @layer base {
  html {
    font-family: Roboto;
  }
} */

body {
  overflow: hidden;
}
